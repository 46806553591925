import { createApi } from '@reduxjs/toolkit/query/react';
import { QUERY } from '@constants/query.constants';
import {
  ICommunityConversationParams,
  IConversation,
  IConversationQueryParams,
  IConversationResponse,
  IConversationRestrictionOptions,
  IConversationSet,
  ICreateConversation,
  ICreateConversationResponse,
  INormalizedConversationResponse,
} from '@ui/components';
import { authQuery } from '../config/query.config';
import { createEntityAdapter } from '@reduxjs/toolkit';
import { cookieService } from '@lib/cookie.service';

export const conversationsAdapter = createEntityAdapter({
  selectId: (item: IConversationSet) => item.conversation.id,
  sortComparer: (a, b) => {
    const lastMessageA = a?.last_message || a?.conversation?.updated_at || ''; // STill suprised to how this perfectly solves the sort issue
    const lastMessageB = b?.conversation?.updated_at || b?.last_message || '';
    return lastMessageB.localeCompare(lastMessageA);
  },
});

export const conversationsSelector = conversationsAdapter.getSelectors();

export const conversationsApi = createApi({
  reducerPath: 'conversations-api',
  baseQuery: authQuery,
  tagTypes: [QUERY.conversations, QUERY.singleConversation, QUERY.communityConversations],
  endpoints: (builder) => ({
    getConversations: builder.query<INormalizedConversationResponse, IConversationQueryParams>({
      query: (params) => ({
        url: 'public/v1/forum/conversation',
        params,
      }),
      providesTags: [QUERY.conversations],
      transformResponse: ({ conversations, total_pages }: IConversationResponse) => {
        const initialState = conversationsAdapter.getInitialState();
        const filtered = conversations
          .filter((conversation) => conversation) // somehow null slips through
          .map((conversationSet) => ({
            ...conversationSet,
            conversation: {
              ...conversationSet.conversation,
              main_thread: conversationSet.conversation.main_thread || conversationSet.thread_id, // fallback thread id
            },
          }));

        return {
          conversations: conversationsAdapter.setAll(initialState, filtered),
          totalPages: total_pages,
        };
      },
      forceRefetch: ({ currentArg, previousArg }) => {
        const isSameCreator = currentArg.creator_id === previousArg?.creator_id;
        const isSamePage = currentArg.page === previousArg?.page;
        return !isSameCreator || !isSamePage;
      },
      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        return `${endpointName}-${queryArgs?.creator_id}`;
      },
      merge: (currentState, incomingState) => {
        conversationsAdapter.addMany(
          currentState.conversations,
          conversationsSelector.selectAll(incomingState.conversations),
        );
      },
    }),
    getCommunityConversations: builder.query<
      INormalizedConversationResponse,
      ICommunityConversationParams
    >({
      query: (params) => ({
        url: 'public/v1/forum/conversation',
        params,
      }),
      providesTags: [QUERY.communityConversations],
      transformResponse: ({ conversations, total_pages }: IConversationResponse, {}) => {
        const initialState = conversationsAdapter.getInitialState();
        const filtered = conversations
          .filter((conversation) => conversation) // somehow null slips through
          .map((conversationSet) => ({
            ...conversationSet,
            conversation: {
              ...conversationSet.conversation,
              main_thread: conversationSet.conversation.main_thread || conversationSet.thread_id, // fallback thread id
            },
          }));

        return {
          conversations: conversationsAdapter.setAll(initialState, filtered),
          totalPages: total_pages,
        };
      },
      forceRefetch: ({ currentArg, previousArg }) => {
        const isSameCreator = currentArg.creator_id === previousArg?.creator_id;
        const isSamePage = currentArg.page === previousArg?.page;
        return !isSameCreator || !isSamePage;
      },
      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        return `${endpointName}-${queryArgs?.creator_id}`;
      },
      merge: (currentState, incomingState) => {
        conversationsAdapter.addMany(
          currentState.conversations,
          conversationsSelector.selectAll(incomingState.conversations),
        );
      },
    }),
    getSingleConversation: builder.query<IConversation, string>({
      query: (id: string) => ({
        url: `public/v1/forum/conversation/${id}`,
      }),
      providesTags: [QUERY.singleConversation],
    }),
    createConversation: builder.mutation<ICreateConversationResponse, ICreateConversation>({
      query: (conversation: ICreateConversation) => ({
        url: `public/v1/forum/conversation`,
        method: 'POST',
        body: conversation,
      }),
      invalidatesTags: [QUERY.conversations, QUERY.communityConversations],
      async onQueryStarted(conversation, { dispatch, queryFulfilled }) {
        const { data } = await queryFulfilled;
        const creator_id = cookieService.getUserId();
        dispatch(
          conversationsApi.util.updateQueryData(
            'getConversations',
            { creator_id, page: 1 } as any,
            (draft) => {
              conversationsAdapter.setOne(draft.conversations, {
                conversation: data.conversation,
                restrictions_list: conversation.restrictions,
                associated_fans: 0,
              });
            },
          ),
        );
      },
    }),
    updateConversation: builder.mutation<
      IConversation,
      Partial<IConversation> & { params: IConversationQueryParams }
    >({
      query: ({ id, ...patch }) => ({
        url: `public/v1/forum/conversation/${id}`,
        method: 'PUT',
        body: patch,
      }),
      invalidatesTags: [QUERY.conversations, QUERY.singleConversation],
      async onQueryStarted({ id, params, ...patch }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          conversationsApi.util.updateQueryData('getConversations', params, (draft) => {
            const conversationSet = conversationsSelector.selectById(draft.conversations, id);
            conversationsAdapter.updateOne(draft.conversations, {
              id,
              changes: {
                ...conversationSet,
                conversation: {
                  ...conversationSet.conversation,
                  ...patch,
                },
              },
            });
          }),
        );

        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),
    deleteConversation: builder.mutation<{ success: boolean; id: string }, string>({
      query(id) {
        return {
          url: `public/v1/forum/conversation/${id}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: [QUERY.conversations],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        const creator_id = cookieService.getUserId();
        const patchResult = dispatch(
          conversationsApi.util.updateQueryData(
            'getConversations',
            { creator_id } as any,
            (draft) => {
              conversationsAdapter.removeOne(draft.conversations, id);
            },
          ),
        );

        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),
    updateConversationRestrictions: builder.mutation<
      IConversationRestrictionOptions,
      { conversationId: string; restrictions: IConversationRestrictionOptions }
    >({
      query: ({ conversationId, restrictions }) => ({
        url: `public/v1/forum/update_restrictions/${conversationId}`,
        method: 'PUT',
        body: { restrictions },
      }),
      invalidatesTags: [QUERY.conversations, QUERY.singleConversation],
      async onQueryStarted({ conversationId, restrictions }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          conversationsApi.util.updateQueryData('getConversations', {} as any, (draft) => {
            const conversationSet = conversationsSelector.selectById(
              draft.conversations,
              conversationId,
            );
            if (conversationSet) {
              conversationsAdapter.updateOne(draft.conversations, {
                id: conversationId,
                changes: {
                  ...conversationSet,
                  restrictions_list: restrictions,
                },
              });
            }
          }),
        );

        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),
  }),
});

export const {
  useGetConversationsQuery,
  useGetSingleConversationQuery,
  useCreateConversationMutation,
  useUpdateConversationMutation,
  useDeleteConversationMutation,
  useGetCommunityConversationsQuery,
  useLazyGetCommunityConversationsQuery,
  useUpdateConversationRestrictionsMutation,
} = conversationsApi;
