import { createApi } from '@reduxjs/toolkit/query/react';
import {
  IMessage,
  IMessagePayload,
  IMessagesQueryParams,
  IReactToMessagePayload,
  INormalizedMessagesOfThreadResponse,
  IReportMessage,
  IMessagesOfThreadResponse,
  ICreateMessageThread,
  TUpdateMessage,
  ICreateMessageResponse,
  ICreateMessageThreadResponse,
  IPostAnalyticsResponse,
} from '@ui/components';
import { QUERY } from '@constants/query.constants';
import { createEntityAdapter, current } from '@reduxjs/toolkit';
import { authQuery } from '../config/query.config';
import { cookieService } from '@lib/cookie.service';
import { mutateUserVoteAction } from '@ui/lib';

export const messagesV2Adapter = createEntityAdapter({
  selectId: (item: IMessage) => item.id,
  sortComparer: (a, b) => (b.tempTimeStamp || 0) - (a.tempTimeStamp || 0),
});

export const messagesV2Selector = messagesV2Adapter.getSelectors();

export const messagesApiV2 = createApi({
  reducerPath: 'messages-api-v2',
  baseQuery: authQuery,
  tagTypes: [QUERY.messages, QUERY.singleMessage],
  endpoints: (builder) => ({
    getMessagesOfThread: builder.query<INormalizedMessagesOfThreadResponse, IMessagesQueryParams>({
      query: ({ thread_id, ...rest }) => ({
        url: `/public/v1/forum/messages_of_thread/${thread_id}`,
        params: rest,
      }),
      providesTags: (result, error, { thread_id }) => [{ type: QUERY.messages, id: thread_id }],
      transformResponse: (response: IMessagesOfThreadResponse) => {
        const mapped = response.list_of_messages.map((m) => ({
          ...m,
          thread_id: response.thread_id,
        }));
        const pinned_messages =
          response?.pinned_messages.map((m) => ({
            ...m,
            thread_id: response.thread_id,
          })) || [];

        const normalized = messagesV2Adapter.addMany(messagesV2Adapter.getInitialState(), mapped);

        return {
          messages: normalized,
          pinnedMessages: pinned_messages,
          totalPages: response.total_pages,
        };
      },
      forceRefetch: ({ currentArg, previousArg }) => {
        const isSameCreator = currentArg.creator_id === previousArg?.creator_id;
        const isSameConversation = currentArg.conversation_id === previousArg?.conversation_id;
        const isSameThread = currentArg.thread_id === previousArg?.thread_id;
        const isSamePage = currentArg.page === previousArg?.page;

        return !isSameCreator || !isSameConversation || !isSameThread || !isSamePage;
      },
      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        const { creator_id, conversation_id, thread_id, page } = queryArgs;
        return `${endpointName}-${creator_id}-${conversation_id}-${thread_id}`;
      },
      merge: (currentState, incomingState) => {
        messagesV2Adapter.addMany(
          currentState.messages,
          messagesV2Selector.selectAll(incomingState.messages),
        );
        currentState.pinnedMessages = incomingState.pinnedMessages;
      },
    }),
    getMessageById: builder.query<IMessage, string>({
      query: (id: string) => {
        const user_id = cookieService.getUserId() as string;
        return {
          url: `/public/v1/forum/message/${id}`,
          params: {
            user_id,
          },
        };
      },
      providesTags: (result, error, id) => [{ type: QUERY.singleMessage, id }],
    }),
    createMessage: builder.mutation<ICreateMessageResponse, IMessagePayload>({
      query: (payload) => ({
        url: `/public/v1/forum/message`,
        method: 'POST',
        body: {
          ...payload,
          files: {
            images: payload.files?.images.map((file) => file.meta),
          },
        },
      }),
      invalidatesTags: [QUERY.singleMessage],
      async onQueryStarted(payload, { dispatch, queryFulfilled, getState }) {
        const { data } = await queryFulfilled;
        if (data) {
          const newMessage: IMessage = {
            ...data.message,
            conversation_id: data.message_thread?.conversation_id,
            thread_id: data.message_thread?.id,
            parent_thread_id: data.message_thread?.id,
            reaction: {
              downvotes: 0,
              upvotes: 0, // Must not upvote if creator
              reactions_list: {},
            },
            user_votes: {
              [data.message?.user_id]: null,
            },
            creator_id: data.message?.creator_id,
            user_id: data.message?.user_id,
            tempTimeStamp: data.message?.single_message ? 0 : Date.now(),
          };
          const currentStateList = messagesV2Adapter.getInitialState();
          const existingDraftList = messagesV2Selector.selectAll(currentStateList);
          const newList = [newMessage, ...existingDraftList];
          const normalized = messagesV2Adapter.setAll(messagesV2Adapter.getInitialState(), newList);

          const queryParams: IMessagesQueryParams = {
            ordering: 'desc',
            creator_id: data.message.creator_id,
            conversation_id: data.message_thread?.conversation_id,
            thread_id: data.message_thread?.id,
            fan_id: data.message.user_id,
            page: 1,
            pageSize: 12,
          };

          // Access the current state
          const currentState = getState();
          const currentQueryData =
            messagesApiV2.endpoints.getMessagesOfThread.select(queryParams)(currentState);
          const pinnedMessages = currentQueryData?.data?.pinnedMessages || [];

          // dispatch(
          //   messagesApiV2.util.upsertQueryData('getMessagesOfThread', queryParams, {
          //     messages: normalized,
          //     pinnedMessages: pinnedMessages,
          //     totalPages: 40, // must align with query param or response - more accurate if returned in pessimistic response
          //   }),
          // );

          dispatch(
            messagesApiV2.util.updateQueryData('getMessagesOfThread', queryParams, (draft) => {
              messagesV2Adapter.addOne(draft.messages, newMessage);
            }),
          );
        }
      },
    }),
    deleteMessage: builder.mutation<void, IMessagesQueryParams & { id: string }>({
      query: ({ id }) => ({
        url: `/public/v1/forum/message/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [QUERY.messages],
      async onQueryStarted(params, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          messagesApiV2.util.updateQueryData('getMessagesOfThread', params, (draft) => {
            messagesV2Adapter.removeOne(draft.messages, params.id);
          }),
        );
        try {
          await queryFulfilled;
        } catch (error) {
          patchResult.undo();
        }
      },
    }),
    reactToMessage: builder.mutation<void, IReactToMessagePayload>({
      query: ({ message_id, reaction, downvote, upvote, user_id }) => ({
        url: `/public/v1/forum/react_to_message/${message_id}`,
        method: 'PUT',
        params: {
          reaction,
          user_id,
          ...(typeof downvote === 'number' && { downvote: 1 }),
          ...(typeof upvote === 'number' && { upvote: 1 }),
        },
      }),
      async onQueryStarted(
        { message_id, slug, reaction, downvote, upvote, params },
        { dispatch, queryFulfilled },
      ) {
        const cookieUserId = cookieService.getUserId();
        const userId = cookieUserId as string;

        const updateMessage = (message: IMessage) => {
          if (!message) return message;
          let user_votes = {
            ...(message.user_votes || { [userId]: null }),
          };
          const preMutatedUserVote = message.user_votes;

          let votes = {
            ...message.reaction,
          };

          if (downvote == 1) {
            votes.downvotes = message.reaction.downvotes + downvote;
            if (preMutatedUserVote?.[userId] === 'upvote') {
              votes.upvotes = message.reaction.upvotes - 1;
            }
          }

          if (downvote == -1) {
            votes.downvotes = message.reaction.downvotes + downvote;
          }

          if (upvote == 1) {
            votes.upvotes = message.reaction.upvotes + upvote;
            if (preMutatedUserVote?.[userId] === 'downvote') {
              votes.downvotes = message.reaction.downvotes - 1;
            }
          }

          if (upvote == -1) {
            votes.upvotes = message.reaction.upvotes + upvote;
          }

          // Handle emoji reactions
          if (reaction) {
            const userReactions = message.user_reactions?.[userId] || [];
            const reactionExists = userReactions.includes(reaction);

            let newUserReactions;
            let newReactionsList = { ...message.reaction.reactions_list };

            if (reactionExists) {
              newUserReactions = userReactions.filter((emoji) => emoji !== reaction);
              newReactionsList[reaction] = Math.max((newReactionsList[reaction] || 0) - 1, 0);
            } else {
              newUserReactions = [...userReactions, reaction];
              newReactionsList[reaction] = (newReactionsList[reaction] || 0) + 1;
            }

            return {
              ...message,
              reaction: {
                ...message.reaction,
                ...votes,
                reactions_list: newReactionsList,
              },
              user_votes: mutateUserVoteAction(user_votes, userId, downvote, upvote),
              user_reactions: {
                ...message.user_reactions,
                [userId]: newUserReactions,
              },
            };
          } else {
            return {
              ...message,
              reaction: {
                ...message.reaction,
                ...votes,
              },
              user_votes: mutateUserVoteAction(user_votes, userId, downvote, upvote),
            };
          }
        };

        const patchListResult = dispatch(
          messagesApiV2.util.updateQueryData('getMessagesOfThread', params, (draft) => {
            const message = messagesV2Selector.selectById(draft.messages, message_id);
            if (message) {
              messagesV2Adapter.updateOne(draft.messages, {
                id: message_id,
                changes: updateMessage(message),
              });
            }

            const pinnedMessage = draft?.pinnedMessages?.find((m) => m.id === message_id);
            if (pinnedMessage) {
              Object.assign(pinnedMessage, updateMessage(pinnedMessage));
            }
          }),
        );

        const patchSingleById = dispatch(
          messagesApiV2.util.updateQueryData('getMessageById', message_id, (draft) => {
            if (draft) {
              Object.assign(draft, updateMessage(draft));
            }
          }),
        );

        const patchSingleBySlug = dispatch(
          messagesApiV2.util.updateQueryData('getMessageById', slug, (draft) => {
            if (draft) {
              Object.assign(draft, updateMessage(draft));
            }
          }),
        );

        try {
          await queryFulfilled;
        } catch {
          patchListResult.undo();
          patchSingleById.undo();
          patchSingleBySlug.undo();
        }
      },
    }),
    reportMessage: builder.mutation<void, IReportMessage>({
      query: ({ message_id, report }) => ({
        url: `/public/v1/forum/report_message/${message_id}`,
        method: 'PUT',
        params: { report },
      }),
    }),
    updateMessage: builder.mutation<IMessage, TUpdateMessage>({
      query: ({ params, files, ...rest }) => ({
        url: `/public/v1/forum/message/${rest.id}`,
        method: 'PATCH',
        body: {
          ...rest,
          files: {
            images: files?.images.map((file) => file.meta),
          },
        },
      }),
      onQueryStarted: async ({ params, slug, id, ...rest }, { dispatch, queryFulfilled }) => {
        const patchListResult = dispatch(
          messagesApiV2.util.updateQueryData('getMessagesOfThread', params, (draft) => {
            // Check if the message is in the pinned messages
            const pinnedMessage = draft?.pinnedMessages?.find((m) => m.id === id);
            if (pinnedMessage) {
              // Update the pinned message
              Object.assign(pinnedMessage, {
                ...pinnedMessage,
                ...rest,
                files: {
                  ...pinnedMessage.files,
                  images: rest.files?.images?.map((file) => file.meta),
                },
              });
            } else {
              // If not pinned, update in the regular messages
              const message = messagesV2Selector.selectById(draft.messages, id);
              if (message) {
                messagesV2Adapter.updateOne(draft.messages, {
                  id,
                  changes: {
                    ...message,
                    ...rest,
                    files: {
                      ...message.files,
                      images: rest.files?.images.map((file) => file.meta),
                    },
                  },
                });
              }
            }
          }),
        );

        const patchSingleBySlug = dispatch(
          messagesApiV2.util.updateQueryData('getMessageById', slug, (draft) => {
            if (draft) {
              Object.assign(draft, {
                ...draft,
                ...rest,
                files: {
                  ...draft.files,
                  images: rest.files?.images.map((file) => file.meta),
                },
              });
            }
          }),
        );

        const patchSingleById = dispatch(
          messagesApiV2.util.updateQueryData('getMessageById', id, (draft) => {
            if (draft) {
              Object.assign(draft, {
                ...draft,
                ...rest,
                files: {
                  ...draft.files,
                  images: rest.files?.images.map((file) => file.meta),
                },
              });
            }
          }),
        );

        try {
          await queryFulfilled;
        } catch {
          patchListResult.undo();
          patchSingleBySlug.undo();
          patchSingleById.undo();
        }
      },
    }),
    createMessageThread: builder.mutation<ICreateMessageThreadResponse, ICreateMessageThread>({
      query: ({ conversation_id, message_id, queryParams }) => ({
        url: `/public/v1/forum/create_message_thread/${message_id}`,
        method: 'POST',
        params: { conversation_id },
      }),
      onQueryStarted: async (
        { conversation_id, message_id, queryParams },
        { dispatch, queryFulfilled },
      ) => {
        const { data } = await queryFulfilled;

        if (!data) {
          return;
        }

        dispatch(
          messagesApiV2.util.updateQueryData('getMessagesOfThread', queryParams, (draft) => {
            const message = messagesV2Selector.selectById(draft.messages, message_id);
            const pinnedMessageIndex = draft?.pinnedMessages?.findIndex((m) => m.id === message_id);
            if (pinnedMessageIndex !== -1) {
              draft.pinnedMessages = draft.pinnedMessages.map((message, index) =>
                index === pinnedMessageIndex
                  ? { ...message, child_thread_id: data.message_thread.id }
                  : message,
              );
            }

            if (!message) return;
            messagesV2Adapter.updateOne(draft.messages, {
              id: message_id,
              changes: {
                ...message,
                child_thread_id: data.message_thread.id,
              },
            });
          }),
        );
      },
    }),
    getPostAnalytic: builder.query<IPostAnalyticsResponse, string>({
      query: (message_id: string) => ({
        url: `/public/v1/forum/message_statistics/${message_id}`,
      }),
      transformResponse: (response: IPostAnalyticsResponse) => {
        return {
          engagement_ratio: response?.engagement_ratio,
          total_votes: response?.total_votes,
          total_reactions: response?.total_reactions,
          total_engagement: response?.total_engagement,
          total_messages: response?.total_messages,
          total_fans: response?.total_fans,
        };
      },
      providesTags: (result, error, message_id) => [{ type: QUERY.singleMessage, id: message_id }],
    }),
    shareMessage: builder.mutation<void, string>({
      query: (message_id) => ({
        url: `/public/v1/forum/increase_shares/${message_id}`,
        method: 'PUT',
      }),
    }),
    pinMessage: builder.mutation<
      IMessage,
      {
        message_id: string;
        user_id: string;
        pin: boolean;
        thread_id: string;
        conversation_id: string;
      }
    >({
      query: ({ message_id, pin, user_id }) => ({
        url: `/public/v1/forum/toggle_pin_message/${message_id}?pin=${pin}&user_id=${user_id}`,
        method: 'PUT',
      }),
      async onQueryStarted(
        { message_id, pin, user_id, thread_id, conversation_id },
        { dispatch, queryFulfilled },
      ) {
        // We need to pass the full query parameters to match the original query
        const patchResult = dispatch(
          messagesApiV2.util.updateQueryData(
            'getMessagesOfThread',
            {
              ordering: 'desc',
              thread_id,
              creator_id: user_id,
              conversation_id,
              fan_id: user_id,
              page: 1,
              pageSize: 12,
            },
            (draft) => {
              if (pin === true) {
                // Find the message in the normalized messages state
                const message = messagesV2Selector.selectById(draft.messages, message_id);

                if (message) {
                  // Remove from messages
                  messagesV2Adapter.removeOne(draft.messages, message_id);
                  // Add to pinnedMessages
                  draft.pinnedMessages.unshift({ ...message, pinned_at: new Date().toISOString() });
                }
              }
              if (pin === false) {
                // Find the message in pinnedMessages
                const pinnedMessage = draft?.pinnedMessages?.find((m) => m.id === message_id);
                if (pinnedMessage) {
                  messagesV2Adapter.addOne(draft.messages, { ...pinnedMessage, pinned_at: null });
                  draft.pinnedMessages = draft.pinnedMessages.filter(
                    (item) => item.id !== message_id,
                  );
                }
              }
            },
          ),
        );

        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),
  }),
});

export const {
  useGetMessageByIdQuery,
  useCreateMessageMutation,
  useDeleteMessageMutation,
  useGetMessagesOfThreadQuery,
  useReactToMessageMutation,
  useReportMessageMutation,
  useUpdateMessageMutation,
  useCreateMessageThreadMutation,
  useLazyGetMessagesOfThreadQuery,
  useShareMessageMutation,
  usePinMessageMutation,
  useLazyGetPostAnalyticQuery,
} = messagesApiV2;
