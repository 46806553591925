import { monetizationApiService } from '@monetization/service';
import { authQuery } from '../config/query.config';

export const monetizationApi = monetizationApiService(authQuery);
export const {
  useCreateStripeConnectAccountMutation,
  useGetOnboardingUrlQuery,
  useGetStripeDashboardLinkQuery,
  useCreateProductTierMutation,
  useDeleteProductTierMutation,
  useUpdateProductTierMutation,
  useGetCreatorProductsQuery,
  useGetProductByIdQuery,
  useGetTransactionsQuery,
  useGetPlatformProductsQuery,
  useGetPlatformProductByIdQuery,
  useGetPlatformSubscriptionsQuery,
  useCreatePlatformSubscriptionMutation,
  useCancelPlatformSubscriptionMutation,
  useUpdatePlatformSubscriptionMutation,
  useResumePlatformSubscriptionMutation,
  useGetPlatformBillingPlanGroupsQuery,
  useGetStripeCustomerPortalMutation,
  useCreateCheckoutSessionMutation,
} = monetizationApi;
